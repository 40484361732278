var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "cartao" },
    [
      _c("v-card-title", { staticClass: "text-h5 mb-4" }, [
        _vm._v("Autenticação (2FA)"),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "switch-tfa-background",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("v-switch", {
                        staticClass: "switch-tfa pl-4 switch-tfa-font",
                        attrs: {
                          label: "Autenticação de dois fatores",
                          inset: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.tfaEnabledForm,
                          callback: function ($$v) {
                            _vm.tfaEnabledForm = $$v
                          },
                          expression: "tfaEnabledForm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "switch-tfa-background pt-4",
                      staticStyle: {
                        "padding-bottom": "10px",
                        "text-align": "right",
                      },
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", { staticClass: "switch-tfa-font" }, [
                        _vm._v(
                          _vm._s(
                            _vm.tfaEnabled === true ? "Ativado" : "Desativado"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("v-col", { staticClass: "px-0" }, [
                    _c("div", { staticClass: "message-obs pt-1" }, [
                      _c("p", [
                        _vm._v(
                          " Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail cadastrado em todas as suas tentativas de acesso ao Agnes. "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "btn-ag-primary",
              attrs: { disabled: !_vm.hasChanged },
              on: { click: _vm.save },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "500px",
            "max-heigth": "285px",
          },
          on: {
            closed: function ($event) {
              return _vm.v$.$reset()
            },
          },
          model: {
            value: _vm.showConfirm,
            callback: function ($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "mb-4 title-obs" },
                [
                  _vm._v(" Autenticação de dois fatores "),
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        color: "var(--v-primary-base)",
                        left: "115px",
                      },
                      attrs: { icon: "" },
                      on: { click: _vm.close },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-title", { staticClass: "disbale-tfa-card-title" }, [
                _vm._v(
                  " Esta ação desativará a autenticação de dois fatores. "
                ),
              ]),
              _c("v-card-title", { staticClass: "disbale-tfa-card-title" }, [
                _vm._v(
                  " Você não receberá mais um código de verificação de segurança se identificarmos uma tentativa de login. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-ag-tertiary",
                      attrs: { text: "" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-ag-primary",
                      on: { click: _vm.deactive },
                    },
                    [_vm._v("Desativar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }