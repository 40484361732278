<template>
  <v-card class="cartao">
    <v-card-title class="text-h5 mb-4">Autenticação (2FA)</v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="mx-0">
          <v-col cols="12" sm="6" class="switch-tfa-background">
            <v-switch
              v-model="tfaEnabledForm"
              label="Autenticação de dois fatores"
              inset
              hide-details
              class="switch-tfa pl-4 switch-tfa-font"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6" style="padding-bottom: 10px; text-align: right" class="switch-tfa-background pt-4">
            <span class="switch-tfa-font">{{ tfaEnabled === true ? 'Ativado' : 'Desativado' }}</span>
          </v-col>
          <v-col class="px-0">
            <div class="message-obs pt-1">
              <p>
                Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail cadastrado
                em todas as suas tentativas de acesso ao Agnes.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-ag-primary" :disabled="!hasChanged" @click="save">Salvar</v-btn>
    </v-card-actions>
    <v-dialog v-model="showConfirm" persistent max-width="500px" max-heigth="285px" @closed="v$.$reset()">
      <v-card>
        <v-card-title class="mb-4 title-obs">
          Autenticação de dois fatores
          <v-btn style="color: var(--v-primary-base); left: 115px" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="disbale-tfa-card-title">
          Esta ação desativará a autenticação de dois fatores.
        </v-card-title>
        <v-card-title class="disbale-tfa-card-title">
          Você não receberá mais um código de verificação de segurança se identificarmos uma tentativa de login.
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-ag-tertiary" text @click="close">Cancelar</v-btn>
          <v-btn class="btn-ag-primary" @click="deactive">Desativar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import userService from '@/services/userService.js';
import { mapGetters } from 'vuex';

import { eventBus } from '../../../main.js';

export default {
  name: 'ChangePassword',
  data: () => ({
    loading: false,
    showConfirm: false,
    tfaEnabledForm: false,
  }),

  computed: {
    ...mapGetters({
      tfaEnabled: 'user/getTfaEnabled',
      userId: 'user/getUserId',
    }),

    hasChanged() {
      return this.tfaEnabledForm !== this.tfaEnabled;
    },
  },

  async mounted() {
    await userService.getUser();
    this.tfaEnabledForm = this.tfaEnabled;
  },

  methods: {
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async deactive() {
      this.showConfirm = false;
      this.tfaEnabledForm = this.tfaEnabled = false;
      await userService
        .disableTwofa(this.userId)
        .then(() => {
          this.showToast('Alterações realizadas com sucesso', 'success', 78);
        })
        .catch(() => {
          this.showToast('Houve um erro ao salvar as alterações', 'error', 78);
        });
    },

    async isActive() {
      if (!this.tfaEnabledForm) {
        this.showConfirm = this.tfaEnabledForm === false;
      } else {
        await userService
          .enableTwofa(this.userId)
          .then(() => {
            this.showToast('Alterações realizadas com sucesso', 'success', 78);
          })
          .catch(() => {
            this.showToast('Houve um erro ao salvar as alterações', 'error', 78);
          });
        this.tfaEnabledForm = this.tfaEnabled = true;
      }
    },

    close() {
      this.showConfirm = false;
      this.tfaEnabled = false;
    },

    async save() {
      if (this.hasChanged) {
        if (this.tfaEnabledForm) {
          this.isActive();
        } else {
          this.deactive();
          // this.showConfirm = true;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/design/variables.scss';

.disbale-tfa-card-title {
  letter-spacing: 0.01em;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  color: #575767;
  font-weight: 700;
  line-height: 150%;
  word-wrap: break-word;
  word-break: break-word;
}

.message-obs {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #8d8d99;
}

.title-obs {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: $neutral-color-high-dark;
}

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: $neutral-color-low-light !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}

.switch-tfa-background >>> .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 4px !important;
}

.green-button {
  color: $feedback-color-success-medium !important;
}

.red-button {
  color: $feedback-color-error-medium !important;
}

.switch-tfa-background {
  background-color: #f9fafb !important;
  border-radius: 4px;
}

.switch-tfa-font {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
}
</style>
